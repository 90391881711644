.userInfoCardBoxWrapper{
    border: 1px solid #8080806e;
    border-radius: 5px;
    padding: 8px;
}
.userInfoCardBoxHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.userInfoNameCardWrapper{
    display: flex;
    column-gap: 5px;
}
.userNameInfoDetails{
    display: flex;
    flex-direction: column;
}
.userNameInfoDetailsOtp{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
}
.userNameInfoDetailsText{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}
.transparentLocationButton{
    font-weight: 600;
    font-size: 13px;
    color: #2B6EEA;
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    column-gap: 6px;
}
.addressParagraph{
    font-size: 13px;
    color: #808080;
    font-weight: 600;
}
.transparentLocationButton:focus{
    background: transparent;
}
.trackLocationBtnWrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.printKotButton{
    background: #e77878;
    border-radius: 3px;
    border: 1px solid grey;
    font-size: 15px;
    font-weight: 500;
}