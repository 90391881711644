.newOrderPromptWrapper {
  position: fixed;
  bottom: 0;
  height: 75px;
  width: 100%;
  background: #3cb857;
  color: #fff;
  text-align: center;
  left: 0;
  z-index: 2;
}
.newOrderPromptButton{
    background: transparent;
    border: 0px;
    color: #fff;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;
    width: 100%;
    padding: 10px;
    height: 100%;
}
.newOrderPromptButton:focus{
    background: transparent;
}
.newOrderPromptText {
  font-size: 13px;
  font-weight: 500;
}
.ant-drawer-header{
    padding: 16px 12px !important;
    background: #3cb857;
}
.ant-drawer-header-title{
    flex-direction: row-reverse;
}
.ant-drawer-close{
    margin-inline-end: 0px !important;
    padding: 0;
}
.ant-drawer-title{
    color: #fff !important;
}
.ant-drawer-body{
    padding: 0px !important;
}