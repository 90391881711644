body,
.ant-drawer {
  font-family: "Okra", "Helvetica", sans-serif !important;
}

h1,
h2,
h3 {
  font-family: "Okra", "Helvetica", sans-serif !important;
}

p {
  font-family: "Okra", "Helvetica", sans-serif !important;
}
.ant-modal {
  font-family: "Okra", "Helvetica", sans-serif !important;
}
.ant-modal-content .ant-btn span {
  font-family: "Okra", "Helvetica", sans-serif !important;
  font-size: 12px;
  font-weight: 600;
}
.ant-modal-content .ant-btn {
  padding: 4px 6px;
  border: 1px solid #808080;
}
.ant-modal-content .ant-btn:focus {
  background: transparent;
}
.appWrapper {
  margin: 16px 18px;
}
h5 {
  margin: 0;
}
button {
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.desktopWrapper {
  max-width: 1200px;
  margin: 0 auto;
}
