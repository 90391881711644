.orderViewCardWrapper {
  margin-top: 54px;
  margin-bottom: 75px;
  max-width: 1200px;
  margin: 70px auto 75px auto;
}

.orderViewCardWrapperChild {
  padding: 10px;
  background: #fff;
  z-index: 1;
  position: relative;
}

@media (max-width: 768px) {
}

.notificationBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
}

.orderHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px 25px 24px;
  border-bottom: 1px solid #80808033;
  margin: 0 -24px 0 -24px;
}

.kitchenCloseIcon {
  padding: 0px;
  margin: 0;
  height: 25px;
  width: 25px;
  border: transparent;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kitchenCloseIcon:focus {
  background-color: #fff;
}

.kitchenMenuTopBar {
  display: flex;
  justify-content: space-between;
}

.kitchenButtonTopBar {
  display: flex;
  column-gap: 8px;
}

.kitchenTopBar {
  padding: 10px 16px 10px 16px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.2), 0 2px 7px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 600px) {
  .kitchenTopBar {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
  }
}

.kitchenTopBarBtn {
  border-radius: 6px;
  padding: 6px 8px;
  font-size: 12px;
  background: #fff;
  border: 1px solid #808080b3;
  color: #808080b3;
  font-weight: 500;
}

.kitchenTopBarBtnActive {
  border: 1px solid #cb202db3;
  color: #cb202db3;
}

.kitchenTopBarBtn:focus {
  background-color: #cb202db3;
  color: #fff;
}

.noOrderHeader {
  text-align: center;
  margin-top: 80px;
}

@media (max-width: 600px) {
  .noOrderHeader {
    margin-top: 94px;
  }
  .orderViewCardWrapper{
    margin-top: 90px;
  }
}

.kitchenSettingDrawer {
  padding: 16px 12px !important;
  margin-top: 50px;
}

.chefDetailsWrapper {
  margin-top: 5px;
  border-top: 1px solid grey;
}

.chefInfoContainer {
  border-bottom: 1px solid grey;
  padding: 8px 0px 10px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}

.chefInfoName {
  font-weight: 500;
}

.chefAvailableLabel {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  column-gap: 5px;
  flex-direction: column;
}

.chefPrepTime {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  column-gap: 5px;
  flex-direction: column;
}
