.header {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.item-name {
  flex-grow: 1;
}

.item-quantity {
  flex-shrink: 0;
}

.total {
  text-align: right;
  font-weight: bold;
  margin-top: 10px;
}

.absoluteCSSOrderReceiptWrapper{
  width: 250px;
  height: 100%;
  top: 0; 
  left: 0;
  pointer-events: none;
  display: inline-block;
}

.order-receipt {
  padding: 10px;
  height: 100%;
  width: 100%;
  border: 1px solid #ccc;
  font-family: Arial, sans-serif;
}

@media print {
  .absoluteCSSOrderReceiptWrapper {
    visibility: visible; /* Make it visible for printing */
  }
}

.font-bold{
  font-weight: bold;
}
