.chefTimingWrapper{
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    display: flex;
}
.chefTimingWrapperBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
}
.scheduleTimePicker input{
    margin: 0px;
}