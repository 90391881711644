.installApp_container {
    padding: 12px 20px;
    z-index: 1;
    background: white;
    font-weight: bold;
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: grey;
  }
  
  .installApp_text {
    width: 70%;
    font-weight: 600;
  }
  
  .installlApp_btn {
    background: #7d9a6f;
    color: white;
    padding: 6px 8px;
  }
  
  .hero_text {
    font-weight: bold;
  }
  