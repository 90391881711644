.chefDishLabel {
    font-weight: 500;
    font-size: 15px;
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
}

.chefDishNameLabel {
    color: #171212;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 400;
}

.chefDishNameLabelWrapper {
    display: flex;
    justify-content: space-between;
    padding: 7px;
    margin: 2px 0px;
    border: 1px solid #d9d9d9;
}

.ChefDishesComponentWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.addDishBtnWrapper {
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.chefDishPrice {
    font-size: 14px;
    font-weight: 400;
}

.ChefDishesDrawerCollapse .ant-collapse-content-box {
    padding: 8px !important;
}

.dish-img-band-img {
    background-color: #fff;
    border: 0;
    border-radius: 100%;
    box-shadow: 0 3px 3px 0 #00000040;
    display: block;
    height: 104px;
    object-fit: cover;
    object-position: center;
    padding: 2px;
    width: 104px;
}

.dish-img-band {
    align-items: center;
    background: linear-gradient(180deg, #eaa315, #e8814d80);
    border-radius: 100%;
    display: flex;
    height: 110px;
    justify-content: center;
    position: relative;
    width: 110px;
}

.dishImgBandBoxWrapper {
    display: flex;
    column-gap: 12px;
}

.dishDescriptionText {
    color: #808285;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    margin-top: 5px;
    max-width: 220px;
}

.chefDishAvailability {
    display: flex;
    align-items: center;
}

.customisableLabel {
    color: #808285;
    font-size: 12px;
    margin-left: 10px;
}

.editDishBtnWrapper {
    display: flex;
    justify-content: end;
    margin-top: 5px;
}

.editDishBtn {
    border: 1px solid #eaa315;
    color: #eaa315;
    font-weight: 500;
    border-radius: 6px;
    padding: 6px 8px;
    font-size: 12px;
    background: #fff;
}

.editDishBtn:focus {
    border: 1px solid #fff;
    color: #fff;
    background: #eaa315;
}

.createKitchen {
    border: 1px solid #1677ff;
    color: #1677ff;
    font-weight: 500;
    border-radius: 6px;
    padding: 6px 8px;
    font-size: 12px;
    background: #fff;
}

.createKitchen:focus {
    border: 1px solid #fff;
    color: #fff;
    background: #1677ff;
}

.editDishInfoModal {
    box-sizing: border-box !important;
    margin: 0 !important;
    padding: 4px 11px !important;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px !important;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100% !important;
    min-width: 0;
    background-color: #ffffff !important;
    background-image: none;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: #d9d9d9 !important;
    border-radius: 6px !important;
    transition: all 0.2s !important;
}

.editDishInfoModal:focus {
    box-shadow: none !important;
}

.editDishModalWrapper {
    display: none;
}

.editDishModalForm .ant-form-item .ant-form-item-label>label {
    font-weight: 400;
}

.editDishModalFormItem {
    margin-bottom: 15px;
}

.editDishModalFormItem .ant-form-item-label {
    text-align: start;
}

.editDishModalFormButton:focus {
    color: #fff;
    background-color: #1677ff !important;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.chefDishAvailabilitySwitch.ant-switch:focus:not(.ant-switch-checked) {
    background: rgba(0, 0, 0, 0.25) !important;
}

.customizeLabelWrapper {
    display: grid;
    width: 100%;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 400;
    grid-template-columns: 1fr 90px 90px;
    column-gap: 12px;
    align-items: center;
}

.customizeOptionsWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.CustomizeComponentWrapper {
    display: flex;
    flex-direction: column;
}

.chefDishAvailabilityCheckbox .ant-checkbox-input {
    position: absolute;
}

.chefDishAvailabilityCheckbox .ant-checkbox+span {
    font-size: 13px !important;
}

.chefDishAvailabilityCheckbox .ant-checkbox-inner {
    display: block !important;
    height: 16px !important;
    position: relative !important;
    padding-left: 0px !important;
}

.chefDishAvailabilityCheckbox .ant-checkbox-inner::before {
    content: none !important;
}

.chefDishAvailabilityCheckbox .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #1677ff !important;
}

.chefDishAvailabilityCheckbox {
    row-gap: 6px;
}

.chefDishAvailabilityCheckbox .ant-checkbox-inner::after {
    opacity: 1 !important;
    transform: rotate(45deg) scale(1) translate(-50%, -50%) !important;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s !important;
    box-sizing: border-box !important;
    position: absolute !important;
    top: 50% !important;
    inset-inline-start: 21.5% !important;
    display: table !important;
    width: 5.7142857142857135px !important;
    height: 9.142857142857142px !important;
    border: 2px solid #fff !important;
    border-top: 0 !important;
    border-inline-start: 0 !important;
    content: "" !important;
    margin-top: 0 !important;
}

.CustomizeComponentBox {
    border-bottom: 1px dotted grey;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.addNewCustomizationOption {
    padding: 2px 5px !important;
    height: 28px;
}

.categoryHeaderLabel {
    margin-bottom: 10px;
    background: rgb(128 128 128 / 32%);
    padding: 6px 10px;
    border: 1px solid grey;
}