.kitchenLine {
  padding: 6px;
  background: #8080804d;
}

.kitchenTimeInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.boldLabel {
  font-weight: 500;
  font-size: 14px;
}

.scheduleTimeLabel {
  font-weight: 500;
  font-size: 12px;
  padding: 3px;
  background-color: #ffa50038;
  border-radius: 6px;
}

.kitchenCardWrapper {
  margin: 10px;
}

.desktopKitchenCardWrapper {
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  border: 1px solid #80808063;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.desktopKitchenCardWrapper>div {
  margin: 8px 0px;
  padding: 0px 8px;
}

.desktopKitchenCardWrapper>div:not(:last-child) {
  border-right: 1px solid #80808063;
}

@media (max-width: 768px) {}

.fontSizeSmall {
  font-size: 12px;
}

.fontSizeMedium {
  font-size: 13px;
}

.orderStatusWrapper {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
}

.orderStatusLabel {
  font-size: 12px;
  font-weight: 500;
  background: #ffa500ad;
  padding: 2px 4px;
  border-radius: 4px;
  color: #fff;
}

.orderDishDetailsWrapper {
  margin: 12px 0px 10px 0px;
}

.orderDishNameInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dishNameLabel {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-weight: 500;
  font-size: 16px;
}

.dotted-underline {
  text-decoration: underline;
}

.nonVegIcon {
  position: relative;
  height: 14px;
  top: -3px;
}

.instructionTextBox {
  background: #ffa50038;
  padding: 10px 11px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.instructionTextLabel {
  font-size: 11px;
  color: #000;
}

.totalBillWrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalBillLeftContainer {
  display: flex;
  align-items: center;
}

.totalBillLabel {
  color: #808080;
  font-weight: 600;
  font-size: 13px;
}

.orderBillStatusLabel {
  margin-left: 10px;
  font-size: 11px;
  font-weight: 500;
  background: #80808033;
  padding: 2px 4px;
  border-radius: 4px;
  color: #808080;
}

.totalBillAmount {
  font-weight: 500;
  font-size: 14px;
  color: #808080;
}

.transparentButton {
  border: 0px;
  background: transparent;
  margin-left: 10px;
  transition: transform 0.3s;
}

.transparentButton:focus {
  background-color: transparent;
}

.transparentButton.open {
  transform: rotate(180deg);
}

.dropdown-container {
  margin-bottom: 10px;
  width: 100%;
  background-color: #f0f0f0;
  box-sizing: border-box;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
}

.totalBillSummaryBox {
  padding: 8px;
}

.billInfoWrapper {
  margin: 2px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userCallIconTag {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.acceptButtonWrapper {
  text-align: center;
  margin-top: 7px;
  bottom: 0px;
  width: calc(100% - 16px);
}

.acceptButtonContent {
  border: 0px;
  background: #2f963f;
  color: #fff;
  padding: 11px 0px;
  border-radius: 7px;
  font-size: 13px;
  font-weight: 500;
  height: auto !important;
  width: 100%;
}
.acceptButtonContent:disabled{
  opacity: 0.5;
  color: #fff;
}
.acceptButtonContent:hover {
  color: #fff !important;
}

.dishNameWithQuantity{
  display: flex; 
  flex-direction: column;
}

.acceptButtonContent span {
  font-size: 13px;
  font-weight: 500;
  font-family: "Okra", "Helvetica", sans-serif !important;
}

.changeStatusSelectWrapper {
  margin-bottom: 6px;
  margin-top: 6px;
  display: grid;
  column-gap: 10px;
  grid-template-columns: 4fr 1fr;
}

.changeStatusSelect {
  font-size: 16px;
  font-weight: 600;
}

.confirmPartnerButton span {
  font-size: 13px;
  font-weight: 500;
  font-family: "Okra", "Helvetica", sans-serif !important;
}

.confirmPartnerButton {
  padding: 4px 8px !important;
}

.confirmPartnerButton:focus {
  background: transparent !important;
}

.changeStatusSelect .ant-select-selection-placeholder,
.changeStatusSelect .ant-select-selection-item {
  font-family: "Okra", "Helvetica", sans-serif !important;
  font-size: 13px;
  font-weight: 500;
}

.ant-select-item-option-content {
  font-family: "Okra", "Helvetica", sans-serif !important;
  font-size: 13px;
}

.acceptButtonContent:focus {
  background: #2f963f;
}

.confirmPartnerButtonWrapper {
  text-align: center;
  margin-bottom: 8px;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}