.order-card {
  border: 1px solid rgba(213, 209, 201, 1);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: white;
}

.order-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px dashed #d9d9db;
}

.order-card-header-user {
  display: flex;
  align-items: center;
}

.order-card-user-details {
  margin-left: 8px;
}

.order-card-user-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
  color: #181c22;
}

.order-card-user-location {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.2px;
  color: #676c6f;
  margin: 0px;
}

.order-card-view-menu {
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  color: #851d1e;
}

.transparentBtn {
  padding: 0px;
  background: transparent;
  border: 0px;
}

.transparentBtn:focus {
  background: transparent;
}

.order-card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  margin: 0;
  border-bottom: 1px dashed #d9d9db;
  margin-bottom: 12px;
}

.order-card-date {
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  color: #676c6f;
}

.orderCardDateWrapper {
  display: flex;
  column-gap: 3px;
}
