.orderMainScreenHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.orderMainOrderTitle {
  font-weight: 600;
  font-size: 16px;
}
.backIconWrapper {
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderInfoId {
  color: #828282;
  font-size: 12px;
  font-family: Averta;
  line-height: 135%;
}
.orderInfoName {
  margin-top: 4px;
  color: #1b1b1b;
  font-size: 18px;
  font-family: Averta;
  font-weight: 600;
}
.orderInfoStatus {
  display: flex;
  align-items: center;
  column-gap: 9px;
  margin-top: 8px;
}
.orderItemLabel {
  color: #828282;
  font-size: 12px;
  font-family: Averta;
  line-height: 135%;
}
.orderInfoStatusLabel {
  color: #f04a3f;
}
.orderStatusChangeBtn {
  margin-top: 12px;
  width: 100%;
  padding: 13px 50px;
  height: 46px;
  border: 1px solid #0e7de3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0e7de3;
  text-align: center;
  font-size: 16px;
  font-family: Averta;
  font-weight: 600;
  &:focus {
    background-color: #0e7de3;
    color: #fff !important;
  }
}
.confirmPartnerBtn {
  background: #0e7de3;
  color: #fff;
}
.orderDetailsWrapper {
  margin-top: 30px;
}
.orderProductDetails {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.userDetailsLabel {
  margin-top: 10px;
}
.userDetailInfoLabel {
  font-family: Averta;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #828282;
}
.userDetailInfoValue {
  color: #1b1b1b;
  font-family: Averta;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.orderProductDetailsNameWrapper {
  color: #1b1b1b;
  font-size: 14px;
  font-family: Averta;
  font-weight: 600;
  line-height: 140%;
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.orderProductDetailsPrice {
  color: #1b1b1b;
  font-size: 14px;
  font-family: Averta;
  line-height: 140%;
}
.deliveryPartnerSelect {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #9e9e9e;
}
.deliveryAddressBox {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #9e9e9e;
}
.deliveryAddressFieldBox {
  display: grid;
  grid-column-gap: 4px;
  column-gap: 4px;
  margin: 6px 0;
  grid-template-columns: max-content auto;
  align-items: flex-start;
}
.openMapsButton {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-family: Averta;
  font-weight: 600;
  border-radius: 8px;
  background: #2156ca;
}
